<template>
  <div id="notice" class="rounded border p-4 bg-white">
    <div
        v-if="pending"
        id="pending"
        class="d-flex flex-column justify-content-center align-items-center align-content-center"
    >
      <h1 class="text-danger">
        LOADING
      </h1>
      <b-spinner
          variant="danger"
          size="lg"
      />
    </div>
    <b-form
        v-else
        v-on:submit.prevent="save"
    >
      <div class="d-flex flex-column">
        <div v-if="info.regDate" class="text-right">
          <div>
            <b-badge>
              {{ new Date(info.regDate).toLocaleString() }}
            </b-badge>
          </div>
          <div>
            <b-badge>
              {{ info.regUserId }}
            </b-badge>
          </div>
        </div>
        <div class="text-left">
          <div class="row">
            <div class="col">
              <b-form-group
                  label="노출 여부"
                  label-size="sm"
              >
                <b-form-checkbox
                    v-model="info.use"
                >
                  {{ info.use ? '노출' : '숨김' }}
                </b-form-checkbox>
              </b-form-group>
            </div>
            <div class="col">
              <b-form-group
                  label="top 여부"
                  label-size="sm"
              >
                <b-form-checkbox
                    v-model="info.top"
                >
                  {{ info.top ? '활성화' : '비활성화' }}
                </b-form-checkbox>
              </b-form-group>
            </div>
          </div>
          <div>
            <div class="mb-1">type</div>
            <b-form-select v-model="info.type" :options="options" size="sm" class="mb-3"></b-form-select>
          </div>
          <b-form-group
              label="제목"
              description="제목을 입력해주세요"
              label-size="sm"
          >
            <b-form-input
                size="sm"
                v-model="info.title"
            />
          </b-form-group>
        </div>
        <div class="editor p-1">
          <editor
              :api-key="API_KEY"
              :init="EDITOR_OPTIONS"
              v-model="info.content"
          />
        </div>
        <hr/>
      </div>
      <div>
        <b-button
            type="submit"
            size="sm"
            squared
            block
        >
          <font-awesome-icon
              icon="save"
          />
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
export default {
  name: "noticeDetail",
  components: {
    Editor,
  },
  data: () => ({
    pending: false,
    info: {
      _id: null,
      type: null,
      title: null,
      content: null,
      regDate: null,
      regUser: null,
      regUserId: null,
      top: false,
      use: true,
    },
    options: [
      { value: 'notice', text: '공지사항' },
      { value: 'event', text: '이벤트' }
    ],
    editor: null,
  }),
  created () {
    (async () => {
      const {_id} = this.$route.params
      if (_id) {
        this.info._id = _id
        await this.load()
      }
    })()
  },
  mounted () {

  },
  beforeDestroy () {
    if (this.editor) this.editor.destroy()
  },
  methods: {
    async load() {
      this.pending = true
      try {
        const {data} = await this.axios({
          url: `/admin/notice/detail/${this.info._id}`,
        })
        const {result, type, title, content, use, regDate, regUserId, regUser, _id} = data
        if (result) this.info = {type, title, content, regDate, regUser, regUserId, _id, use}
      } catch (e) {
      }
      this.pending = false
    },

    async save () {
      if (!confirm(`${this.info._id ? '수정' : '등록'}하시겠습니까?`)) return
      this.pending = true
      try {
        const { data } = await this.axios({
          url: this.info._id ? `/admin/notice/save/${this.info._id}` : `/admin/notice/save`,
          method: this.info._id ? 'PATCH' : 'POST',
          data: {
            info: this.info
          }
        })
        const {result, error} = data
        if (result) {
          await this.$router.push({
            path: `/notice`
          })
        } else {
          this.$bvToast.toast(`${error}`, {
            title: 'ERROR',
            autoHideDelay: 5000,
            variant: 'danger',
            appendToast: false
          })
        }
      } catch (e) {
      }
      this.pending = false
    }
  }
}
</script>

<style scoped>
#notice {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5rem;
}
</style>
